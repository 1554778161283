<template>
  <b-row
    v-loading="loading"
    class="match-height business-fill"
  >
    <b-col class="business-fill-left">
      <b-card>
        <div
          class="flex-between"
          style="align-items: baseline"
        >
          <div
            class="left-wrap"
            :style="{maxHeight: pageH - 160 + 'px'}"
          >
            <div
              v-for="(item,index) in ixitList"
              :key="index"
              :class="pActive === index ? 'active-cls' : ''"
              class="left-item box-rect"
              @click="leftClick(item,index)"
            >
              {{ item.title }}
              <div
                class="badge-box"
                :class="statusColorObj[item.status]"
              >
                {{ statusObj[item.status] }}
              </div>
            </div>
          </div>
          <div
            class="right-wrap"
            :style="{maxHeight: pageH - 160 + 'px'}"
          >
            <div
              v-for="(item,index) in childList"
              :key="index"
              :class="cActive === index ? 'sub-active-cls' : ''"
              class="left-item box-rect sub-rect dot"
              @click="rightClick(item,index)"
            >
              {{ index + 1 }}、 {{ item.title }}
              <sup
                v-if="item.finishStatus !== 3"
                class="el-badge__content is-fixed is-dot"
              />
            </div>

          </div>
        </div>

      </b-card>
    </b-col>
    <b-col class="business-fill-right">
      <b-card v-loading="loading">
        <div
          class="flex-between mb10"
          style="height: 30px"
        >
          <div class="flex-start">
            <span class="title">{{ title }}</span>
            <i
              :class="activeItem.finishStatus !== 3 ? 'icon-chulizhong' : 'icon-yiwancheng'"
              class="iconfont status-icon"
            />
            <el-tooltip
              v-if="activeItem.haveOverrule"
              content="驳回信息"
              placement="top"
              effect="light"
            >
              <i
                class="el-icon-warning cursor-pointer ml10"
                style="font-size: 18px;
    color: #F56C6C;"
                @click="rejectDialogShow"
              />
            </el-tooltip>

            <el-tooltip
              v-if="activeItem.havaAnnex"
              content="下载附件"
              placement="top"
              effect="light"
            >
              <i
                class="el-icon-download cursor-pointer ml10"
                style="font-size: 16px;color: #0d40b2"
                @click="downloadFile"
              />
            </el-tooltip>
          </div>
          <div class="flex-end">
            <el-button
              class="mr10"
              @click="back"
            >
              返 回
            </el-button>
            <el-badge
              :is-dot="activeItem.havaAnnex"
              class="mr10"
            >
              <el-button @click="uploadFileDialogShow">
                附件与备注
              </el-button>
            </el-badge>
            <el-button
              v-if="activeItem.finishStatus !== 3"
              type="primary"
              @click="updateStatus"
            >
              标记完成
            </el-button>
          </div>
        </div>
        <IxitItem
          v-if="initRight"
          ref="ixitItem"
          :style="{maxHeight: pageH - 160 + 'px'}"
          style="overflow-y: scroll;overflow-x: hidden"
          :item-id="itemId"
          :active="cActive"
          :title="title"
          :active-item="activeItem"
          :activeLeftItem="ixitList[pActive]"
          @handleFilter="getItxtDetailsList"
        />
      </b-card>
    </b-col>
    <IxitUploadFile
      ref="ixitUploadFile"
      @handleFilter="activeItem.havaAnnex = true"
    />
    <RejectDialog ref="rejectDialog" />
  </b-row>
</template>

<script>
import {
  UpdateStatus, GetItxtDetailsList, DownloadAttachment, GetSelectItemList,
} from '@/api/business/business'
import IxitItem from '@/views/dataIntelligent/business/IxitItem.vue'
import { error, success } from '@/@core/utils/utils'
import IxitUploadFile from '@/views/dataIntelligent/business/IxitUploadFile.vue'
import RejectDialog from '@/views/dataIntelligent/business/RejectDialog.vue'

export default {
  components: {
    IxitItem,
    IxitUploadFile,
    RejectDialog,
  },
  data() {
    return {
      pActive: 0,
      cActive: 0,
      title: '',
      statusObj: {
        1: '待填写',
        2: '填写中',
        3: '填写完成',
        4: '重新填写',
      },
      statusColorObj: {
        1: 'bg-secondary',
        2: 'bg-primary',
        3: 'bg-success',
        4: 'bg-warning',
      },
      ixitList: [{ itemList: [] }],
      childList: [],
      loading: false,
      itemId: 0,
      tableData: [],
      dialogVisible: false,
      initRight: false,
      id: Number(this.$route.query.id),
      activeItem: {
        havaAnnex: false,
        haveOverrule: false,
      },
      activeLeftItem: {
        status: 0,
      },
    }
  },
  created() {
    this.getItxtDetailsList()
  },
  mounted() {
    this.navActiveInit(1, '业务信息数据', '303_645评估')
  },
  destroyed() {
    this.navActiveInit(2, '业务信息数据', '303_645评估', '/business')
  },
  methods: {
    downloadFile() {
      DownloadAttachment(this.itemId)
    },
    rejectDialogShow() {
      this.$refs.rejectDialog.dialogVisible = true
      this.$refs.rejectDialog.form = this.activeItem
    },
    back() {
      this.$router.push({ path: '/business' })
    },
    checkFinsih() {
      let hasFinish = true
      this.childList.forEach(item => {
        if (item.finishStatus !== 3) {
          hasFinish = false
        }
      })
      this.ixitList[this.pActive].status = hasFinish ? 3 : this.ixitList[this.pActive].status
    },
    updateStatus() {
      this.$confirm('是否确认认证机制填写完成?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          const submitData = {
            id: this.itemId,
            status: 3,
          }
          UpdateStatus(submitData)
            .then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.childList[this.cActive].finishStatus = 3
                this.checkFinsih()
                // this.back()
              } else {
                error(resData.msg)
              }
            })
            .catch(() => {
              this.loading = false
            })
        })
    },
    uploadFileDialogShow() {
      this.$refs.ixitUploadFile.dialogVisible = true
      this.$refs.ixitUploadFile.form.id = this.activeItem.id
      this.$refs.ixitUploadFile.getInfo()
    },
    leftClick(item, index) {
      this.initRight = false
      this.pActive = index
      // const unFinishIndex = this.ixitList[this.pActive].itemList.findIndex(item => item.finishStatus !== 3)
      // if (unFinishIndex !== -1) {
      //   this.cActive = unFinishIndex
      //   this.itemId = this.ixitList[index].itemList[unFinishIndex].id
      //   this.title = this.ixitList[index].itemList[unFinishIndex].title
      //   this.activeItem = this.ixitList[index].itemList[unFinishIndex]
      // } else {
      //   this.cActive = 0
      //   this.itemId = this.ixitList[index].itemList[0].id
      //   this.title = this.ixitList[index].itemList[0].title
      //   this.activeItem = this.ixitList[index].itemList[0]
      // }
      // this.getSelectItemList(item.id)

      this.$nextTick(() => {
        GetSelectItemList(item.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.childList = resData.data
            // const unFinishIndex = this.childList.findIndex(item => item.finishStatus !== 3)
            const unFinishIndex = this.childList.findIndex(item => item.needSelect)
            if (unFinishIndex !== -1) {
              this.cActive = unFinishIndex
              this.itemId = this.childList[unFinishIndex].id
              this.title = this.childList[unFinishIndex].title
              this.activeItem = this.childList[unFinishIndex]
            } else {
              this.cActive = 0
              this.itemId = this.childList[0].id
              this.title = this.childList[0].title
              this.activeItem = this.childList[0]
            }
          }
          this.initRight = true
        })
      })
    },
    rightClick(item, index) {
      this.activeItem = item
      this.cActive = index
      this.itemId = item.id
      this.title = item.title
      this.initRight = false
      this.$nextTick(() => {
        this.initRight = true
      })
    },
    getItxtDetailsList() {
      this.loading = true
      this.initRight = false
      GetItxtDetailsList(this.id)
        .then(res => {
          this.loading = false
          const resData = res.data
          if (resData.code === 0) {
            this.ixitList = resData.data
            if (this.ixitList.length > 0) {
              this.ixitList.forEach((item, index) => {
                if (item.id === this.id) {
                  this.pActive = index
                  this.childList = item.itemList
                  // const unFinishIndex = this.childList.findIndex(item => item.finishStatus !== 3)
                  const unFinishIndex = this.childList.findIndex(item => item.needSelect)
                  if (unFinishIndex !== -1) {
                    this.cActive = unFinishIndex
                    this.itemId = this.childList[unFinishIndex].id
                    this.title = this.childList[unFinishIndex].title
                    this.activeItem = this.childList[unFinishIndex]
                  } else {
                    this.cActive = 0
                    this.itemId = this.childList[0].id
                    this.title = this.childList[0].title
                    this.activeItem = this.childList[0]
                  }
                }
              })
            }
            this.initRight = true
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
.business-fill {
  width: 100%;

  .left-wrap {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
  }

  .left-wrap,
  .right-wrap {
    max-height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .business-fill-left {
    min-width: 360px;
  }

  .business-fill-right {
    min-width: calc(100% - 360px);
  }

  .active-cls {
    background: #0d40b2;
    color: #ffffff;
  }

  .sub-active-cls {
    background: rgb(115, 103, 240);
    color: #ffffff;
  }

  .box-rect {
    position: relative;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 120px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .sub-rect {
    width: 130px;
    min-height: 30px;
  }
}

.badge-box {
  position: absolute;
  border-radius: 10px;
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  right: -10px;
  top: -10px;
  color: #ffffff;
}

.status-icon {
  font-size: 48px;
}

.icon-yiwancheng {
  color: #28c76f;
}

.icon-chulizhong {
  color: rgb(255, 159, 67);
}
</style>
