<template>
  <div>
    <div class="text-right">
      <el-button
        v-if="noTableArr.indexOf(active) === -1 && activeItem.finishStatus !== 3"
        type="primary"
        class="mb10"
        @click="dialogVisible = true"
      >
        添 加
      </el-button>
    </div>
    <el-table
      v-if="noTableArr.indexOf(active) === -1"
      v-loading="loading"
      fit
      border
      highlight-current-row
      :data="tableData"
      stripe
      style="width: 100%"
    >
      <el-table-column
        v-for="(item,index) in ixitFields"
        :key="index"
        :prop="item.field"
        :show-overflow-tooltip="true"
        :label="item.label"
      />
      <el-table-column
        v-if="activeItem.finishStatus !== 3"
        label="操作"
        width="165px"
      >
        <template slot-scope="{row}">
          <el-link
            :underline="false"
            class="mr10"
            type="primary"
            @click="copyPolicy(row)"
          >
            <el-tooltip
              content="复制"
              placement="top"
              effect="light"
            >
              <i
                class="el-icon-document-copy"
              />
            </el-tooltip>
          </el-link>
          <el-link
            class="mr10"
            :underline="false"
            type="primary"
            @click="updateRow(row)"
          >
            <el-tooltip
              content="修改"
              placement="top"
              effect="light"
            >
              <i class="el-icon-edit" />
            </el-tooltip>
          </el-link>
          <el-link
            :underline="false"
            type="primary"
            @click="del(row)"
          >
            <el-tooltip
              content="删除"
              placement="top"
              effect="light"
            >
              <i class="el-icon-delete" />
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="dialogVisible"
      :class="noTableArr.indexOf(active) === -1 ? 'box-shadow-1' : ''"
      style="padding: 20px;margin-top: 20px"
    >
      <el-form
        ref="form"
        v-loading="loading"
        :model="form"
        label-width="120px"
        :disabled="activeItem.finishStatus === 3"
      >
        <el-form-item
          v-for="(item,index) in ixitFields"
          :key="index"
          class="form-item-flex"
          :label="item.label"
        >
          <el-input
            v-if="item.formType === 1"
            v-model="form[item.field]"
            :placeholder="item.desc"
            type="textarea"
            :autosize="{ minRows: 3}"
          />
          <el-select
            v-else
            v-model="form[item.field]"
            style="width: 200px"
            class="mr10"
            @change="form[item.fileName] = ''"
          >
            <el-option
              v-for="(f,k) in fileTypeOptions"
              :key="k"
              :value="f.value"
              :label="f.label"
            />
          </el-select>
          <div v-if="form[item.field] ===1">
            <input
              :id="'uploadFileInput'+index"
              type="file"
              style="display: none"
              @change="uploadFileInputChange(index,item)"
            >
            <el-input
              v-model="form[item.fileName]"
              disabled
              placeholder="请上传文件"
              class="upload-file-input"
            >
              <template slot="append">
                <div class="flex-start">
                  <div
                    style="cursor: pointer;"
                    @click="uploadFileClick(index)"
                  >
                    选择文件
                  </div>
                  <i
                    v-if="form[item.field] ===1 && form[item.fileName]"
                    class="el-icon-download cursor-pointer ml10"
                    style="font-size: 16px;color: #0d40b2"
                    @click="downloadDocFile(index)"
                  />
                </div>

              </template>
            </el-input>
          </div>
          <el-input
            v-if="form[item.field] ===2"
            v-model="form[item.filePath]"
            style="width: 200px"
            :placeholder="item.desc"
          />
          <el-tooltip
            :content="item.desc"
            placement="top"
            effect="light"
          >
            <i
              class="el-icon-info cursor-pointer mr10"
              style="font-size: 16px;color: #0d40b2;position: relative;top: 10px;left: 5px"
            />
          </el-tooltip>
        </el-form-item>
      </el-form>
      <div
        v-if="activeItem.finishStatus !== 3"
        class="text-center"
      >
        <el-button
          v-if="noTableArr.indexOf(active) === -1"
          @click="dialogVisible = false;initForm()"
        >
          取 消
        </el-button>
        <el-button
          type="primary"
          :disabled="loading"
          @click="saveOrUpdateItem"
        >
          保 存
        </el-button>
      </div>
    </div>

  </div>
</template>
<script>
import { ixitFields } from '@/views/dataIntelligent/business/ixitField'
import {
  GetItemDetailsList, saveOrUpdateItemDetails, DelIxitDetail, UpLoadUserDoc, saveOrUpdateItemOther, GetItemOtherInfo, DownloadDocFile,
} from '@/api/business/business'
import { error, success } from '@/@core/utils/utils'

export default {
  props: {
    activeItem: {
      type: Object,
    },
    activeLeftItem: {
      type: Object,
    },
    active: {
      type: Number,
    },
    itemId: {
      type: Number,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      noTableArr: [0, 2, 4, 9],
      ixitFields: ixitFields[this.active],
      loading: false,
      dialogVisible: false,
      fileTypeOptions: [
        { label: '请选择文件类型', value: 0 },
        { label: '文件', value: 1 },
        { label: 'url', value: 2 },
      ],
      tableData: [],
      form: {
        changeFileName: '',
        baseInfo: '',
        replaceFileName: '',
        sensorFileName: '',
        securitySettingsFileName: '',
        configCheckFileName: '',
        personalDataFileName: '',
        telemetryDataFileName: '',
        deleteFunctionFileName: '',
        action: '',
        allocationMechanism: '',
        allowConfig: '',
        authenticationFactor: '',
        authenticationMechanism: '',
        bruteForcePrevention: '',
        bugAction: '',
        bugMonitor: '',
        config: '',
        cannotUpdatePublic: '',
        changeDocPath: '',
        changeMechanismDoc: undefined,
        communicationMechanism: '',
        configCheckDoc: undefined,
        configCheckDocPath: '',
        confirmation: '',
        debugInterface: '',
        deleteFunctionDoc: undefined,
        deleteFunctionDocPath: '',
        describe: '',
        detectionMechanism: '',
        disclosureInfo: '',
        disclosurePolicyPublish: '',
        encryptionDetails: '',
        encryptionUsage: '',
        generationMechanism: '',
        getAgree: '',
        id: 0,
        idCode: '',
        isolation: '',
        itemId: this.itemId,
        modelName: '',
        notifyFunction: '',
        option: '',
        personalData: '',
        personalDataDoc: undefined,
        personalDataDocPath: '',
        processActivity: '',
        protect: '',
        protectionPlan: '',
        purpose: '',
        pwdGenMechanism: '',
        reason: '',
        recoveryMeasures: '',
        replace: '',
        replaceDoc: undefined,
        replaceDocPath: '',
        report: '',
        reviewAssessMethod: '',
        revokeAgree: '',
        safetyManageProcess: '',
        securityAssurance: '',
        securityDevProcess: '',
        securitySettingsDoc: undefined,
        securitySettingsDocPath: '',
        security_detection: '',
        sensitivity: '',
        sensorDoc: undefined,
        sensorDocPath: '',
        startAndInteract: '',
        securityDetection: '',
        status: '',
        supportPeriod: '',
        supportPeriodPublish: '',
        telemetryDataDoc: undefined,
        telemetryDataDocPath: '',
        timeRange: '',
        triggerMethod: '',
        type: '',
        updateMechanism: '',
        updateProgram: '',
        userNotify: '',
      },
      id: Number(this.$route.query.id),
    }
  },
  created() {
    if (this.noTableArr.indexOf(this.active) === -1) {
      this.dialogVisible = false
      this.getItemDetailsList()
    } else {
      this.dialogVisible = true
      this.getItemDetails()
    }
  },
  methods: {
    uploadFileInputChange(index, item) {
      const uploadDom = document.getElementById(`uploadFileInput${index}`)
      const fileVal = uploadDom.files
      this.form[item.fileName] = fileVal[0].name
      const formData = new FormData()
      formData.append('file', uploadDom.files[0])
      formData.append('itemId', this.itemId)
      formData.append('docType', index + 1)
      UpLoadUserDoc(formData).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.activeLeftItem.status = 2
          this.form[item.filePath] = resData.data.attachmentSavePath
          success(resData.msg)
        } else {
          error(resData.msg)
        }
      })
    },
    downloadDocFile(index) {
      DownloadDocFile(this.itemId, index + 1)
    },
    uploadFileClick(index) {
      document.getElementById(`uploadFileInput${index}`).click()
    },
    // 获取右边table数据
    getItemDetailsList() {
      GetItemDetailsList(this.itemId).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.tableData = resData.data
        }
      })
    },
    // 获取表单详情
    getItemDetails() {
      GetItemOtherInfo(this.itemId, this.active).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
        }
      })
    },
    initForm() {
      this.form = {
        baseInfo: '',
        changeFileName: '',
        replaceFileName: '',
        sensorFileName: '',
        securitySettingsFileName: '',
        configCheckFileName: '',
        personalDataFileName: '',
        telemetryDataFileName: '',
        deleteFunctionFileName: '',
        action: '',
        allocationMechanism: '',
        allowConfig: '',
        config: '',
        authenticationFactor: '',
        authenticationMechanism: '',
        bruteForcePrevention: '',
        bugAction: '',
        securityDetection: '',
        bugMonitor: '',
        cannotUpdatePublic: '',
        changeDocPath: '',
        changeMechanismDoc: undefined,
        communicationMechanism: '',
        configCheckDoc: undefined,
        configCheckDocPath: '',
        confirmation: '',
        debugInterface: '',
        deleteFunctionDoc: undefined,
        deleteFunctionDocPath: '',
        describe: '',
        detectionMechanism: '',
        disclosureInfo: '',
        disclosurePolicyPublish: '',
        encryptionDetails: '',
        encryptionUsage: '',
        generationMechanism: '',
        getAgree: '',
        id: 0,
        idCode: '',
        isolation: '',
        itemId: this.itemId,
        modelName: '',
        notifyFunction: '',
        option: '',
        personalData: '',
        personalDataDoc: undefined,
        personalDataDocPath: '',
        processActivity: '',
        protect: '',
        protectionPlan: '',
        purpose: '',
        pwdGenMechanism: '',
        reason: '',
        recoveryMeasures: '',
        replace: '',
        replaceDoc: undefined,
        replaceDocPath: '',
        report: '',
        reviewAssessMethod: '',
        revokeAgree: '',
        safetyManageProcess: '',
        securityAssurance: '',
        securityDevProcess: '',
        securitySettingsDoc: undefined,
        securitySettingsDocPath: '',
        security_detection: '',
        sensitivity: '',
        sensorDoc: undefined,
        sensorDocPath: '',
        startAndInteract: '',
        status: '',
        supportPeriod: '',
        supportPeriodPublish: '',
        telemetryDataDoc: undefined,
        telemetryDataDocPath: '',
        timeRange: '',
        triggerMethod: '',
        type: '',
        updateMechanism: '',
        updateProgram: '',
        userNotify: '',
      }
    },
    saveOrUpdateItem() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.itemId = this.itemId
          if (this.noTableArr.indexOf(this.active) === -1) {
            saveOrUpdateItemDetails(this.form)
              .then(res => {
                this.loading = false
                const resData = res.data
                if (resData.code === 0) {
                  this.activeLeftItem.status = 2
                  this.getItemDetailsList()
                  success(resData.msg)
                  this.dialogVisible = false
                  this.initForm()
                } else {
                  error(resData.msg)
                }
              }).catch(() => {
                this.loading = false
              })
          } else {
            saveOrUpdateItemOther(this.form).then(res => {
              this.loading = false
              const resData = res.data
              if (resData.code === 0) {
                this.activeLeftItem.status = 2
                this.getItemDetailsList()
                success(resData.msg)
                this.getItemDetails()
              } else {
                error(resData.msg)
              }
            })
          }
        }
      })
    },
    copyPolicy(row) {
      this.dialogVisible = true
      for (const i in this.form) {
        this.form[i] = row[i]
      }
      this.form.id = 0
    },
    updateRow(row) {
      this.dialogVisible = true
      for (const i in this.form) {
        this.form[i] = row[i]
      }
    },
    del(row) {
      this.$confirm('是否确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        closeOnClickModal: false,
      })
        .then(() => {
          DelIxitDetail({ id: row.id })
            .then(res => {
              const resData = res.data
              if (resData.code === 0) {
                success(resData.msg)
                this.getItemDetailsList()
              } else {
                error(resData.msg)
              }
            })
        })
    },

  },
}
</script>
<style lang="scss">
.upload-file-select .el-input{
  //width: 200px !important;
}
</style>
