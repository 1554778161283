<template>
  <el-dialog
    title="驳回信息"
    :visible.sync="dialogVisible"
    width="620px"
    :append-to-body="true"
    @close="dialogClose"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form mr10"
      label-width="100px"
    >
      <el-form-item
        label="审核人："
      >
        <span>{{ form.auditName }}</span>
      </el-form-item>
      <el-form-item
        label="驳回时间："
      >
        <span>{{ form.auditTime }}</span>
      </el-form-item>
      <el-form-item
        label="驳回理由："
      >
        <span>{{ form.auditReason }}</span>
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        关 闭
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: '',
        auditTime: '',
        auditReason: '',
      },
    }
  },
  methods: {
    dialogClose() {
      this.form = {
        id: '',
        status: '',
        responsiblePersonId: '',
      }
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
