<template>
  <el-dialog
    title="附件与备注"
    :visible.sync="dialogVisible"
    width="620px"
    @close="dialogClose"
    @open="dialogOpen"
  >
    <el-form
      v-if="dialogVisible"
      ref="form"
      v-loading="loading"
      :model="form"
      class="assest-save-form mr10"
      label-width="100px"
    >
      <el-form-item
        label="附件"
        prop="fileName"
        :rules="[ { required: true, message: '请上传文件', trigger: 'change' }]"
      >
        <input
          id="uploadFileInput"
          type="file"
          style="display: none"
          @change="uploadFileInputChange"
        >
        <el-input
          v-model="form.fileName"
          disabled
          class="upload-file-input"
          placeholder="请上传文件"
        >
          <template slot="append">
            <div
              style="cursor: pointer;"
              @click="uploadFileClick"
            >
              选择文件
            </div>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
        label="备注信息"
      >
        <el-input
          v-model="form.attachmentRemark"
          :autosize="{ minRows: 5}"
          type="textarea"
          placeholder="备注信息"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="dialogVisible = false">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        保 存
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { success, error } from '@core/utils/utils'
import { GetAttachment, UpLoadAttachment } from '@/api/business/business'

export default {
  props: {
    activeItem: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      form: {
        id: 0,
        fileName: '',
        attachmentRemark: '',
      },
    }
  },
  methods: {
    uploadFileClick() {
      document.getElementById('uploadFileInput').click()
    },
    uploadFileInputChange() {
      const uploadDom = document.getElementById('uploadFileInput')
      const fileVal = uploadDom.files
      this.form.fileName = fileVal[0].name
    },
    dialogOpen() {

    },
    dialogClose() {
      this.form = {
        id: 0,
        fileName: '',
        attachmentRemark: '',
      }
      this.$refs.form.resetFields()
    },
    getInfo() {
      if (this.form.id !== 0) {
        GetAttachment(this.form.id).then(res => {
          const resData = res.data
          if (resData.code === 0) {
            this.form.attachmentRemark = resData.data.attachmentRemark
            this.form.fileName = resData.data.attachmentName
          } else {
            error(resData.msg)
          }
        })
      }
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const formData = new FormData()
          formData.append('id', this.form.id)
          formData.append('fileName', this.form.fileName)
          formData.append('attachmentRemark', this.form.attachmentRemark)
          const uploadDom = document.getElementById('uploadFileInput')
          if (uploadDom.files.length > 0) {
            formData.append('file', uploadDom.files[0])
          }
          this.loading = true
          UpLoadAttachment(formData, this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.$emit('handleFilter')
              this.dialogVisible = false
            } else {
              error(resData.msg)
            }
          }).catch(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>

<style>
.assest-save-form {
  padding-right: 10px;
}
.assest-save-form .el-icon-question {
  position: absolute;
  top: 14px;
  right: -20px;
}
</style>
